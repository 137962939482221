* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    
}

#nav {
    display: block;
    margin-bottom: 50px;
}

#nav2 {
    display: none;
}

a {
    text-decoration: none;
}

nav.navbar {
    background-color: white;
    box-shadow: 0px 15px 15px -0px rgba(0, 0, 0, 0.1);

}

.left-navbar {
    display: flex;
    flex-direction: row;
    gap: 50px;
    width: auto;
}

.nav-logo img {
    max-height: 10vh;
    cursor: pointer;
}

.nav-search {
    height: 50px;
    padding-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid;
    margin-top: 5px;
}

.nav-search input {
    padding: 0 5px;
    background-color: transparent;
    border: none;
    outline: none;
    color: black;
    width: 200px;
}

.search-icon svg {
    cursor: pointer;
    color: black;
    margin-left: -5px;
}


.right-navbar {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 10px;

}

#account {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    color: black;
    gap: 5px;
    align-items: center;
    padding: 8px 0 0 0;
    margin-right: 15px;

}

.account-title {
    text-decoration: none;

}

#logout:hover {
    background-color: rgb(236, 195, 195);
    padding-bottom: 10px;
}

.fav-icon {
    margin-right: 15px;
    color: black;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: black;
    gap: 5px
}

.fav-icon {
    font-weight: 600;
}

.right-navbar button {
    margin-top: -1px;
    padding: 10px 15px;
    color: black;
    border-radius: 15px;
}

.right-navbar button:hover {
    border: 1px solid black;
}

.right-navbar button span {
    font-size: large;
    font-weight: normal;
}


a {
    text-decoration: none;
    color: black;
}



.navBtn {
    border-bottom: 1px solid;
    cursor: pointer;
    padding: 16px;
    transition: 0.5s;
    color: black;
}

.navBtn:hover {
    border: 1px solid black;
    padding: 10px 15px;
    border-radius: 10px;
    color: black;
}

.offcanvas-body {
    flex-grow: 0;
}

#nav2 {
    display: none;
}

@media screen and (max-width: 1400px) {

    #nav {
        display: none;
    }

    #nav2 {
        display: inline-block;
        min-width: 100%;
    }

    .nav2-container {
        display: flex;
        flex-direction: row;
        gap: 20px;
        padding: 10px;
    }

    .nav2-right-navbar {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 25px;
        width: 100%;
    }

    .nav2-Login,
    .nav2-Register,
    .nav2-account,
    .nav2-fav-icon,
    .nav2-upload-product {
        display: flex;
        flex-direction: row;
        padding: 20px 25px;
        align-items: center;
        gap: 40px;
        margin-top: 25px;
        cursor: pointer;
        width: 100%;
        justify-content: center;

    }

    .nav2-Login:hover,
    .nav2-Register:hover,
    .nav2-account:hover,
    .nav2-fav-icon:hover,
    .nav2-upload-product:hover {
        background: lightblue;
    }
}
@media screen and (max-width: 425px) {
#sidebar{
    display: none;
}
.nav-search{
    display:none;
}
}