.container-login {
    max-width: 960px;
    margin: 0 auto;
    padding: 20px;
min-height: 70vh;
}

.container-login a {
    text-decoration: none;
}

.LogIn {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background: linear-gradient(145.44deg, #F2F4FA 0%, #FAFBFC 100%);
    border-radius: 20px;
    padding: 20px;
    margin-bottom: 3rem;
}

/* Form stilleri */
.LogIn h1 {
    font-size: 28px;
    margin-top: 10px;
    text-align: center; /* Add this line to center the text */
}

.logo {
    justify-content: center;
    align-items: center;
}

.loginİmage img {
    margin: 20% 0px;
    max-width: 100%;
    height: auto;
}

.form {
    padding: 20px;
}

.Item {
    font-weight: bold;
}

.input {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.forget-password {
    float: right;
}



.forget-password a {
    padding: 50px 0px;
}

.forget-password:hover {
    text-decoration: underline;
}

#Login {
    width: 100%;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    padding: 10px;

}

#Login button {
    width: 100%;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;

}

#Login button>p {
    background-color: #007bff;

}

.Link-account {
    text-align: center;
}


@media screen and (max-width:991px) {

    .image img {
        margin: 0px 15%;
        max-width: 100%;
        height: auto;
    }

    .form {
        display: flex;
        flex-direction: column;
    }

}