.section{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
@media screen and (max-width: 1200px) {
  .section{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
 
}

