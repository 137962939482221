.step-form {
    max-width: 400px;
    margin: 15% auto 0;
    padding: 20px 20px 100px 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background: #cfcccc;;
}

.col-md-4.col-sm-8.col-10{
    padding: 30px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background: linear-gradient(145.44deg, #F2F4FA 0%, #FAFBFC 100%);
    border-radius: 20px
}

.step1-btn {
    margin-top: 45px;
    gap: 20px;
    float: right;
}


input[type='number'],
input[type='text'],
textarea,
input[type='file'] {
    width: 100%;
    padding: 8px;
}

#productRoom,
#propertyType,
#category,
#subCategory {
    width: 100%;
    padding: 8px;
    margin-bottom: 20px;
}

.submitBtn {
    padding: 8px 12px;
    margin-top: 10px;
    cursor: pointer;
    border: none;
    border-radius: 4px;
}
