.Account2{
  display: none;
}
.menu {
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 29%;
  z-index: 5;
  background: linear-gradient(145.44deg, #F2F4FA 0%, #FAFBFC 100%);
  padding: 30px;
  border-radius: 20px;
  height: 450px;
  width: 350px;
  margin-bottom: 30px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin-top: 30px;
}

.AccountButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 100px;
}

.DetailMenuAccount,
.DetailMenuProduct {
  
  border: none;
  background: transparent;
  padding-bottom: 10px;
  width: 160px;
  border-bottom: 2px solid transparent;
  transition: border-bottom 0.3s ease;
}

.DetailMenuAccount.selected,
.DetailMenuProduct.selected {
  border-bottom: 2px solid black;
}

@media (max-width: 1200px) {
  .menu {
    width: 250px; 
    margin-top: 100px;
    margin-bottom: 87px;
  }

  .AccountButtons {
    margin-right: 75px; 
  }

}

@media (max-width: 992px) {
  .Account{
    display: none;
  }
  .Account2{
    display: inline-block;
  }
  div .col-md-12{
    text-align: -webkit-center;
  }
  div#root{
    text-align: center;
  }
  .menu {
    position: sticky;
    top: 0;
    z-index: 5;
    width: 250%;
    max-width: 500px;
    height: 100px;
    margin-top: 20px; /* Navbar'ın yüksekliği kadar bir boşluk bırak */
    /* margin-left: 50%; */
    margin-bottom: 0;
  }

  .Account {
    margin-top: 20px;
  }

  .AccountButtons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 10px; 
  }
}


  @media (max-width: 772px) {
    .menu {
      width: 100%;
      margin-left: 0;
      position: sticky;
      top: 0;
    }
    .AccountButtons{
      margin-right: 0px;
    }
  }

