body .SignIn {
  font-family: Arial, sans-serif;
}

.Item {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.SignIn h1 {
  font-size: 28px;
  margin-top: 10px;
  text-align: center;
  /* Add this line to center the text */
}

#Signin {
  width: 100%;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 10px;


}

input.form-control{
width: 100% !important;
height: 41.6px !important;
}

#Signin button {
  width: 100%;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;

}

.formx {
  margin: auto;
  width: 80%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background: linear-gradient(145.44deg, #F2F4FA 0%, #FAFBFC 100%);
  border-radius: 20px;
  display: flex;
  align-items: center;
}


.imagex {
  margin: 0;
  width: 80%;
  height: auto;
  float: left;
}


.imagex img {
  width: 120%;
  height: auto;
}

/* Label'ları sağa ve sola yerleştirme */
.label-container {
  display: flex;
  justify-content: space-between;
}

.input-container {
  width: 48%;
  /* 4 label sağda, 4 label solda */
  margin-bottom: 10px;
}


.signIn-inputs {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 100%; /* Genişlik ayarı */
  margin-bottom: 5px;
}



.signInBtn {
  background-color: #4caf50;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.signInBtn:hover {
  background-color: #45a049;
}

.error {
  color: red;
  margin-top: 5px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.error {
  animation: fadeIn 0.5s ease-out;
}

@media screen and (max-width:1024px) {
  .LogIn{
    /* width: 130%; */
    padding: 10px
  }
}