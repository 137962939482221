.forgot-password-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.image {
  width: 60%;
  margin-right: 20px; 
}

.image img {
  max-width: 100%;
  height: auto;
  margin-left: 0px auto;
}

.form-container {
  width: 400px;
  height: auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.inputs{
  display: flex;
  flex-direction: column;
}

.form {
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 10px;
}

.ForgotPasswordİnput {
  padding: 8px;
  margin-bottom: 15px;
  max-width: 100%;
}

.ForgotPasswordBtn {
  background-color: #007bff;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.ForgotPasswordBtn:hover {
  background-color: #0056b3;
}

.message {
  margin-top: 15px;
}
