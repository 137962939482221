* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
@media screen and (max-width:1000px) {
    .products img{
       width: auto;
    }
    
}