.detail{
  max-width: 1000px;
}
@media screen and (max-width: 768px) {
  .detail {
    justify-content: center;
    text-align: center;
    margin-top: 100px;
  }

}