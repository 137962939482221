@media screen and (max-width: 3000px) {
   #searchMain.nav-search{
       display: none;
   }
 }

 @media screen and (max-width: 493px) {
   #searchMain.nav-search{
       display:flex;
   }
 }