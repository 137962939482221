* {
    box-sizing: border-box;
}

body{
    background-color: white;
    color: black;
}

.footer-logo img {
    max-height: 10vh;
    cursor: pointer;
}

ul {
    cursor: pointer;
    list-style: none;

}

 ul>li:hover { 
    text-decoration: underline;
}

.icons {
    padding: 30px 0;
    display: flex;
    cursor: pointer;
    justify-content: center;
}

h3 {
    padding-top: 25px;
    text-align: center;
}

@media (max-width: 991px) {
    #logo {
        align-items: center;
    }

    #footer-links {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 50px;
        width: auto;
    }
}