
.products {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.products div.img {
    padding:  10px;
}

.products img {
    /* width: 100px; */
    border-radius: 10px;

}
.products a{
    text-decoration: none;
    color: black;
}

